/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header { background: #000000  center 0 no-repeat; background-size: auto 100%; color: #fff; }

.header .header__inner { display: flex; justify-content: space-between; align-items: center; height: 80px;  padding: 15px 0; }

/*  Header Links  */
.header .header__links { font-size: 15px; }
.header .header__links ul { display: flex; list-style: none outside none; }
.header .header__links li + li { margin-left: 77px;}
.header .header__links button { width: 90px; margin-top:5px; text-decoration: none; text-transform: uppercase; }
.header .header__links a { width: 90px; margin-top:5px; text-decoration: none; text-transform: uppercase; }
.header .header__inner .items__quantity {     position: absolute;
    bottom: 18px;
    right: 8px;width: 17px;
    height: 17px;
    text-align: center;
    background-color: #D0021B!important;
	color: #fff;
	font-size: 10px;
    line-height: 17px;
	border-radius: 50%;}
.header img { max-width: none; }
.header .logo { text-decoration: none; font-size: 0; }
.header .logo img { width: 156px; }
.header .logo .logo__slogan { position: relative; top: 1px; display: inline-block; vertical-align: middle; padding-left: 18px; margin-left: 2px; font-family: 'Futura' ; font-size: 16px; line-height: 1.2; color: #fff; }
.header .logo .logo__slogan:before { content: ''; position: absolute; top: 5px; bottom: 5px; left: 0; width: 1px; background: $color-gray; }
.header .logo .logo__slogan span { display: block; }
.header .control__panel { color: #d9e2f3; font-size: x-large; }
.header .mobile__shoplist{ display: none; }


@media #{$mobile} {
	.header .header__links { display: none; }
	.header .mobile__shoplist{ display: block; }
	.header-wrap--hide-filters .filters-toggles { display: none; }
}

@media (max-width: 460px) {
	.header .logo__slogan{
		display: none!important;
	}
}

@media (max-width: 370px) {
	.header .logo img{
		width: 140px!important;
	}

	.header .back__acdelco__arabia{
		width: 105px!important;
	}
}