.part-number-dropdown {
    
    display: inline-block;
    z-index: 101;
    position: absolute;
    background-color: #efeff5;
}


.part-number-dropdown .item {
    background-color: #f4f4f4 !important;
    border-radius: 0;
    padding-left: 0px;
    font-size: 20px;
    padding-right: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
    border: solid ;
    border-color: #d0d0e1;
    border-width: thin;
    color: #0066ff;
}


.part-number-dropdown .button-select   {
    background-color: white !important;
    font-size: 20px;
    padding: auto;
    color: #0066ff;
}


.part-number-dropdown button {
        background-color: white !important;
        border: none ;
        color: #0066ff;
    }
    