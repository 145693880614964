.categories-list {
    list-style: none;
}

ul.submenuItems a {
    text-decoration: none;
    border-bottom: 1px solid rgb(255, 255, 255);
}

.accordion-menu {
    width: 100%;
    max-width: 230px;
    margin: 20px auto 20px;
    background: #666666;
    border-radius: 1px;
}

.accordion-menu li.open .dropdownlink {
    color: white;
}

.accordion-menu .rah-static.rah-static--height-auto:last-child .dropdownlink {
    border-bottom: 0;
}

.dropdownlink {
    cursor: pointer;
    display: block;
    padding: 7px 10px 7px 10px;
    font-size: 14px;
    border-bottom: 1px solid #ccc;
    color: white;
    position: relative;
    transition: all 0.4s ease-out;
    i {
        position: absolute;
        top: 17px;
        left: 16px;
    }
}

.submenuItems {
    background: lightgray;
    list-style: none;
}

.submenuItems a {
    display: block;
    font-size: 12px;
    color: #005DAB;
    padding: 5px 12px 5px 25px;
    transition: all 0.4s ease-out;
}

.submenuItems .selected a {
    background-color: #005DAB;
    color: white;
}

.submenuItems a:hover {
    background-color: #005DAB;
    color: white;
}