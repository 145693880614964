/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
    font-family: 'Louis';
    src: url('../../fonts/Louis-Regular.woff2') format('woff2'),
        url('../../fonts/Louis-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Louis';
    src: url('../../fonts/Louis-Italic.woff2') format('woff2'),
        url('../../fonts/Louis-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Louis';
    src: url('../../fonts/Louis-Bold.woff2') format('woff2'),
        url('../../fonts/Louis-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Louis';
    src: url('../../fonts/Louis-BoldItalic.woff2') format('woff2'),
        url('../../fonts/Louis-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Futura';
    src: url('../../fonts/Futura-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura';
    src: url('../../fonts/Futura-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Futura';
    src: url('../../fonts/Futura-Bold-Italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Futura';
    src: url('../../fonts/Futura-Book-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
