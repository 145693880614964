/* ------------------------------------------------------------ *\
	Articles
\* ------------------------------------------------------------ */

.articles .articles__inner { display: flex; flex-wrap: wrap; margin: 0 -15px; }
.articles .article { width: 33.33%; padding: 0 15px; margin-bottom: 30px; }

@media #{$mobile} {
	.articles .article { width: 100%; margin-bottom: 15px; }
}

/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article .article__image { height: 150px; margin-bottom: 8px; background-position: 50% 50%; background-size: cover; }
.article .article__title { padding-bottom: 5px; border-bottom: 1px solid #ccc; margin-bottom: 8px; }

/* ------------------------------------------------------------ *\
	Article Single
\* ------------------------------------------------------------ */

.article-single { font-size: 16px; }
.article-single .article__title { margin-bottom: 11px; font-size: 24px; text-transform: uppercase; }
.article-single .article__entry { margin-bottom: 20px; }
.article-single .article__note { margin-bottom: 26px; color: $color-blue; }

@media #{$mobile} {
	.article-single { font-size: 12px; }
	.article-single .article__title { margin-bottom: 0; font-size: 15px; }
	.article-single .article__note { display: none; }
	.article-single .article__image { margin: 0 -15px; }
}