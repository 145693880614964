/* ------------------------------------------------------------ *\
	Browse Filters
\* ------------------------------------------------------------ */

.browse-filters .browse-filters__selected-filters { position: absolute; top: 10px; right: 15px; padding: 0; list-style: none outside none; display: flex; font-size: 15px; }
.browse-filters .browse-filters__selected-filters li + li { margin-left: 11px; }
.browse-filters .browse-filters__selected-filters strong { font-weight: normal; color: $color-blue; font-style: italic; text-transform: uppercase; }
.browse-filters .shell { position: relative; display: flex; overflow-x: hidden;}
.browse-filters .browse-filters-mobile { display: none }

@media #{$mobile} {
	.browse-filters .browse-filters-mobile { display: block }
	.browse-filters .browse-filters-web { display: none }
	.browse-filters .browse-filters-mobile { position: absolute; top: 82px; bottom: 57px; left: 0; right: 0; z-index: 10; background: #fff; }
	.browse-filters .browse-filters-mobile .shell {display: flex; flex-direction: column; height: 100%; padding: 0; }
	
	.browse-filters .browse-filters-mobile .browse-filters__selected-filters { display: block; position: static; padding: 8px 12px; border-bottom: 1px solid #ccc; margin: 0; font-style: italic; }
	.browse-filters .browse-filters-mobile .browse-filters__selected-filters li + li { margin: 0; }
	.browse-filters .browse-filters-mobile .browse-filters__selected-filters span { display: inline-block; min-width: 60px; }
	.browse-filters .browse-filters-mobile .browse-filter { flex: 1; }

}


/* ------------------------------------------------------------ *\
	Browse Filter
\* ------------------------------------------------------------ */

.browse-filter .browse-filter-web { padding: 10px 0 15px; display: flex;}
.browse-filter .browse-filter-web .browse-filter__title { margin-bottom: 10px; font-size: 17px; color: #333; }
.browse-filter .browse-filter-web .browse-filter__title span { text-transform: uppercase; }

.browse-filter .browse-filter-web .browse-filter__options { display: flex; flex-flow: wrap; margin: 0 -3px; }
.browse-filter .browse-filter-web .browse-filter__option { min-width: 6.25%; padding: 0 3px 1px; }
.browse-filter .browse-filter-web .browse-filter__option .option-input { position: absolute; z-index: -1; opacity: 0; }
.browse-filter .browse-filter-web .browse-filter__option .option-label { text-align: center; background-color: #f5f5f5; color: black; display: block; padding: 5px; text-decoration: none; border-bottom: 1px solid lightgray }
.browse-filter .browse-filter-web .browse-filter__option .option-label:hover{ background: #ccc; }
.browse-filter .browse-filter-web .browse-filter__option-selected { text-align: center; background-color: #005dab; color: white; display: block; padding: 5px; text-decoration: none; border-bottom: 1px solid lightgray }

.browse-filter .browse-filter-web .browse-filter__actions { display: none; }

.browse-filter .browse-filter-mobile { display: none; }

@media #{$mobile} {
	.browse-filter .browse-filter-mobile{ display: block; }
	.browse-filter .browse-filter-web{ display: none; }
	.browse-filter .browse-filter-mobile { padding: 0 0 15px;}
	.browse-filter .browse-filter-mobile .browse-filter__title { margin-bottom: 10px; font-size: 17px; color: #333; }
	.browse-filter .browse-filter-mobile .browse-filter__title span { text-transform: uppercase; }

	.browse-filter .browse-filter-mobile .browse-filter__options { position: relative; bottom: 0; left: 0; right: 0; overflow: auto; display: block; padding: 0 12px; margin: 0 -3px; }	
	
	.browse-filter .browse-filter-mobile .browse-filter__option { min-width: 6.25%; padding: 0; }
	.browse-filter .browse-filter-mobile .browse-filter__option input { position: absolute; z-index: -1; opacity: 0; }
	.browse-filter .browse-filter-mobile .browse-filter__option label { display: block; padding: 4px 10px; background: #757171; font-size: 15px; color: #fff; text-align: center; }
	.browse-filter .browse-filter-mobile .browse-filter__option label:hover,
	.browse-filter .browse-filter-mobile .browse-filter__option input:checked + label { background: #404040; color: #00b0fe; }

	.browse-filter .browse-filter-mobile .browse-filter__head { position: relative; padding: 10px 12px; border-bottom: 1px solid #a0a0a0; }	
	
	.browse-filter .browse-filter-mobile .browse-filter__option + .browse-filter__option { border-top: 1px solid #ccc; }	
	
	.browse-filter .browse-filter-mobile .browse-filter__option label:hover,
	.browse-filter .browse-filter-mobile .browse-filter__option label { background: transparent; font-size: 18px; color: #114f98; text-transform: uppercase; }	

	.browse-filter .browse-filter-mobile .browse-filter__actions { position: absolute; top: 7px; right: 12px; display: block; font-size: 20px; color: #000; }
	
	.browse-filter .browse-filter-mobile .browse-filter__title { margin: 0; }
}

/* ------------------------------------------------------------ *\
	Filters Toggles
\* ------------------------------------------------------------ */

.filters-toggles { display: none; }

@media #{$mobile} {
	.filters-toggles { display: block; padding: 18px 0; }
	.filters-toggles .btn-blank { position: relative; display: block; width: 100%; padding: 7px 12px; border-width: 1px 0; border-style: solid; border-color: #c5c5c5; background: linear-gradient(to bottom, #ededed 0%, #cdcdcd 100%); font-size: 15px; color: #333; text-align: left; text-transform: uppercase; }
	.filters-toggles .btn-blank:before { content: ''; position: absolute; top: 50%; right: 12px; width: 0; height: 0; border-width: 10px 5px 0 5px; border-style: solid; border-right-color: transparent; border-left-color: transparent; margin-top: -5px; }
}
/* ------------------------------------------------------------ *\
	Filters Active
\* ------------------------------------------------------------ */

@media #{$mobile} {
	.filters-active .main { display: none; }
}