.universal-parts-items{
    margin: auto;
    display: flex;
    padding-top: 10px;
}

@media #{$mobile} {
    .universal-parts-items{
        display: block;
    }
}