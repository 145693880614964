.dataAdmin {
  margin-top: 0;
  margin-left: 15%;
  margin-right: 15%;
  text-align: center;
  font-family: 'Futura';
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 70%;
  
}

.dataAdmin td,
.dataAdmin th {
  color:#525258;
  border: 1px solid #ddd;
  padding-top: 8px;
  padding-bottom: 8px;
}

.dataAdmin tr:nth-child(even) {
  background-color: #f2f2f2;
}

.dataAdmin tr:hover {
  background-color: #ddd;
}
.dataAdmin th{
 // color:#525258;
  background-color: rgb(216, 216, 216);
}

.table-footer {
    display: flex;
    flex-direction: column ;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 15%;
    margin-right: 15%;
   }

   .table-footer button {
     margin-top:10%;
     margin-bottom:10%;
     width: 75px
   }
  

  .table-footer strong {
    margin-right: 20px;
   }



#titleDataAdmin {
  border: 1px solid #ddd;
  font-family: 'Futura';
  margin-bottom: 0;
  margin-top: 5%;
  margin-left: 15%;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: rgb(217, 226, 243);
  color: rgb(0, 0, 0);
  width: 70%;
}
