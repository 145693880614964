/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

$font-family-sans-serif: 'Futura', sans-serif;

$font-size-base: 12px;
$line-height-base: 1.3;

$shell-max-width: 1200px;

$color-lightgray: #c2c2c2;
$color-gray: #999;
$color-gray-item-titles: #7b7b7b;
$color-darkgray: #666;
$color-black: #000;
$color-blue: #0066ff;

$responsive-xxs: 374px;
$responsive-xs: 1000px;
$responsive-sm: 1023px;
$responsive-md: 1200px;

$transition-duration: .15s;

$mobile-small: '(max-width: #{$responsive-xxs}) ';
$mobile: '(max-width: #{$responsive-xs}) ';
$tablet-portrait: '(max-width: #{$responsive-sm}) ';
$small-desktop: '(max-width: #{$responsive-md}) ';
$retina: '(min-resolution: 2dppx) ';

// Usage
// @media #{$small-desktop}
