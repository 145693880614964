/* ------------------------------------------------------------ *\
	Search
\* ------------------------------------------------------------ */

.search { padding: 22px 0; }

.search .search__inner { display: flex; max-width: 400px; margin: auto; }
.search .search__field { flex: 1; height: 35px; padding: 0 10px; border: 1px solid $color-gray; border-right: 0; border-radius: 0; font-size: 15px; color: $color-darkgray; transition: $transition-duration; }
.search .search__field::placeholder { color: $color-gray; }
.search .search__field:focus { border-color: $color-darkgray; }
.search .search__btn { width: 43px; border: 0; background: #4360c0; transition: $transition-duration; font-size: 18px; color: #fff; }
.search .search__btn:hover { background-color: #30468f; }
.search .search__close { display: none; }

@media #{$mobile} {
	.search { position: absolute; top: 82px; bottom: 57px; left: 0; right: 0; z-index: 10; padding: 13px 12px; background: #fff; }
	.search .search__field { height: 48px; padding: 0 43px; background: #fafafa; border: 0; border-radius: 3px; box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3); }
	.search .search__inner { position: relative; }
	.search .search__btn { position: absolute; top: 0; left: 0; width: 48px; height: 100%; background: transparent; color: #626262; }

	.search .search__close { display: block; position: absolute; top: 0; right: 0; width: 48px; height: 100%; background: transparent; border: 0; font-size: 20px; color: $color-darkgray; appearance: none; }
}