
.no-parts {
    color: black;
}

.no-parts-title {
    font-size: 20px;
    color: #0066ff;
    font-weight: bold;
    padding-bottom: 25px;
    padding-top: 30px;
}


.no-parts .search-tips {
    margin-bottom: 20px;
    font-size: 14px;
}

.no-parts .no-parts-list li{
    font-weight: bold;
    font-size: 14px;
    margin-top: 10px;
    line-height: 2.5;
    // list-style-position: inside;
    list-style: disc;
    margin-left: 1rem;
}

