.shoplist__main h3{
    margin: 20px;
    text-align: center;
    color: black;
}

.shoplist__main h3:nth-of-type(2){
    margin-bottom: 0;   
}

.shoplist__main .removeItem{
    text-decoration: none;
    color: red;
}

.shoplist__main table{
    width: 970px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    color: black;
    border-bottom:2px solid black;
}

.shoplist__main tr{
    height: 40px;
    border-top:1px solid black;
    border-bottom:1px solid black;
}

.shoplist__main .quantity__control{
    height: 25px;
    width: 40px;
}

.shoplist__main .table .header_line{
    border-top:2px solid black;
    border-bottom:2px solid black;
}

.shoplist__main .table .header_line th{
    color: black;
}

.shoplist__main .table th:nth-child(1),
.shoplist__main .table td:nth-child(1){
    width: 50%;
    text-align: left;
} 

.shoplist__main .table td:nth-child(2),
.shoplist__main .table td:nth-child(3),
.shoplist__main .table td:nth-child(4),
.shoplist__main .table td:nth-child(5){
    text-align: center;
} 

.shoplist__main .shoplist__form{
    text-align: center;
} 

.shoplist__main .shoplist__form .container{
    display: inline-block;
    padding: 0;
} 

.shoplist__main .form__input{
    display: block;
    padding: 20px;
}

.shoplist__main .form__input .form__item{
    display: flex;
    margin-bottom: 10px;
}

.shoplist__main .form__input .form__item strong{
    font-weight: normal;
    width: 80px;
    min-width: 80px;
    padding: 2px;
    text-align: left;
    font-size: 15px;
    color: black;
}

.shoplist__main .form__input .form__item .item__input{
    width: 100%;
}

.shoplist__main .form__input .form__item input{
    border: 1px ridge black; 
    align-self: flex-start;
    width: 885px;
    height: 25px!important;
    color: black;
    padding: 0;
    padding-left: 3px;
}

.shoplist__main .react-inputs-validation__select__dropdown___11yDr{
    height: 23px;
    padding-left: 3px;
    font-size: 14px;
}

.shoplist__main .react-inputs-validation__select__options-container___d54qE{
    width: 885px;
    margin: 0;
    border: 1px solid black;
    color: black;
}

.shoplist__main .react-inputs-validation__select__options-item___30yY4.react-inputs-validation__active___3eZBB,
.shoplist__main .react-inputs-validation__select__options-item___30yY4{
    padding-top: 5px!important;
}

.shoplist__main .react-inputs-validation__select__hover-active___2z1MQ{
    background: gray;
}

.shoplist__main .react-inputs-validation__select__options-item___30yY4,
.shoplist__main .react-inputs-validation__select__container___3jgUR{
    padding: 0;
    height: 25px;
}
.shoplist__main .react-inputs-validation__select__container___3jgUR{
    border: 1px solid black;
    color: black;
}

.shoplist__main .form__input .form__item textarea{
    height: 60px;
    border: 1px ridge black; 
    align-self: flex-start;
    color: black;
    padding: 0;
    padding-left: 3px;
    margin-bottom: 15px;
}

.shoplist__main .checkbox__label{
    margin-left: 5px;
}

.shoplist__main .form__input .form__item .checkbox__item{
    display: flex;
}

.shoplist__main .form__input .form__item .checkbox__item a:hover{
    color: $color-blue;
}

.shoplist__main .react-inputs-validation__checkbox__box___1uj8A{
    min-width: 15px;
    bottom: 1px;
}

.shoplist__main .react-inputs-validation__checkbox__box___1uj8A,
.shoplist__main .react-inputs-validation__checkbox__box___1uj8A .react-inputs-validation__box___3E9nu{
    border-color: black;
    margin: 0;
}

.shoplist__main .react-inputs-validation__checkbox__box___1uj8A .react-inputs-validation__box___3E9nu{
    width: 9px;
    height: 5px;
}

.shoplist__main .react-inputs-validation__checkbox__wrapper___1c1rD{
    height: fit-content;
    margin-right: 10px;
}

.shoplist__main .form__input .form__item .checkbox__text{
    text-align: justify;
    width: 850px;
    color: black;
}

.shoplist__main .form__input .form__item input:-webkit-autofill{
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.shoplist__main .form__input .form__item .react-inputs-validation__error___2aXSp {
    border-color: crimson;
}

.shoplist__main .form__input .form__item .checkbox__item .react-inputs-validation__msg___pxv8o{
    display: none;
}

.shoplist__main .form__input .form__item .react-inputs-validation__msg___pxv8o {
    text-align: left;
    color: crimson;
}

.shoplist__main .shoplist__form .form__button{
    text-align: center;
    margin-bottom: 20px;
}

.shoplist__main .shoplist__form .form__button button:disabled{
    background: lightgray;
}

.shoplist__main .shoplist__form .btn--block:nth-child(1){
    margin-right: 40px;
}

.message_box{
    border: 1px solid black;
    padding: 10px 50px 10px 50px;
    text-align: center;
}

.message_box span{
    white-space: pre-line;
    color: black;
    display: block;
    margin-top: 10px;
    font-size: 14px;
}

.message_box h3{
    color: black;
    font-weight: bold;
}

.message_box .btn--block{
    display: block!important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 80px!important;
}

.shoplist__main .shoplist__form .btn--block,
.message_box .btn--block{
    width: 130px;
    display: inline-block; 
    font-size: 12px;
    vertical-align: middle; 
    padding: 0 15px; 
    border: 0; 
    border-radius: 0; 
    background: linear-gradient(to bottom, #0f6c9e 0%, #004588 100%); 
    color: #fff; 
    line-height: 23px; 
    text-align: center; 
    appearance: none; 
    text-decoration: none; 
}

.shoplist__main .shoplist__form .btn--block{
    margin-top: 25px;
}

.shoplist__main .shoplist__form .btn--block:hover,
.message_box .btn--block:hover{
  background: linear-gradient(to bottom, #004588 0%, #0f6c9e 100%);
}

.shoplist__main .shoplist__form .shoplist__captcha div{
    margin-left: auto;
    margin-right: auto;
}

@media #{$mobile} {
    
    .shoplist__main table{
        width: 92%;
    }

    .shoplist__main .container,
    .shoplist__main .form__input .form__item input,
    .shoplist__main .react-inputs-validation__select__options-container___d54qE,
    .shoplist__main .form__input .form__item .checkbox__text{
        width: 100%;
    }

    .shoplist__main .table th:nth-child(1),
    .shoplist__main .table td:nth-child(1){
        width: 30%;
    }

    .shoplist__main .quantity__control{
        width: 65px;
    }
}

@media (max-width: 380px){
    .shoplist__main .shoplist__form .btn--block{
        width: 130px;
    }

    .shoplist__main .form__input{
        padding-right: 10px;
        padding-left: 10px;
    }
}