body {
    font-family: Futura;
    font-size: 12px;
    background-color: white;
  }
  
  .startClick {
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 40px;
    font-size: 15px;
  }
  
  .tit {
    font-weight: normal;
    text-align: center;
    font-size: 13px;
    text-decoration: none;
  }
  
  .vertMenuNarrow {
    position: relative;
    width: 365px;
    height: 170px;
    overflow-y: auto;
    float: left;
    margin-top: 5px;
    border: 1px solid lightgray;
    background-color: #eee;
  }
  .vertMenuNarrow a {
    text-align: center;
    background-color: #f5f5f5;
    color: black;
    display: block;
    padding: 5px;
    text-decoration: none;
    border-bottom: 1px solid lightgray;
  }
  .vertMenuNarrow a:hover {
    background-color: #ccc;
  }
  .vertMenuNarrow a.active {
    background-color: #005dab;
    color: white;
    font-weight: bold;
  }
  
  .vertMenuWide {
    position: relative;
    width: 365px;
    height: 170px;
    overflow-y: auto;
    float: left;
    margin-top: 5px;
    border: 1px solid lightgray;
    background-color: #eee;
  }
  .vertMenuWide a {
    text-align: center;
    background-color: #f5f5f5;
    color: black;
    display: block;
    padding: 5px;
    text-decoration: none;
    border-bottom: 1px solid lightgray;
  }
  .vertMenuWide a:hover {
    background-color: #ccc;
  }
  .vertMenuWide a.active {
    background-color: #005dab;
    color: white;
    font-weight: bold;
  }
  
  .resp {
    position: absolute;
    padding-top: 280px;
    padding-left: 20px;
    color: #4a4f54;
    font-size: 15px;
  }
  
  li {
    list-style: none;
  }
  
  .extDivWide {
    width: 365px;
    height: 185px;
    // height: auto;
    float: left;
    margin: 0 17px 0 17px;
    background-color: white;
    text-align: center;
  }
  
  .extDivNarrow {
    width: 365px;
    height: 185px;
    float: left;
    margin-left: 33px;
    background-color: white;
    text-align: center;
  }


@media (max-width: 1220px) {
  .extDivWide,
  .vertMenuWide{
    width: 295px;
  }
}

  