/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body { min-width: 320px; background: #fff; font-family: $font-family-sans-serif; font-size: $font-size-base; line-height: $line-height-base; color: $color-gray; }

a { color: inherit; text-decoration: underline; transition: $transition-duration; }

em {font-family: Futura; font-style: normal; }

a:hover,
a[href^="tel"] { text-decoration: none; }

h1,
h2,
h3,
h4,
h5,
h6 { margin-bottom: 0.7em; color: $color-darkgray; font-weight: normal; }

h1 { font-size: 20px; }
h2 { font-size: 18px; }
h3 { font-size: 15px; }
h4 { font-size: 14px; }
h5 { font-size: 12px; }
h6 { font-size: 10px; }

p,
ul,
ol,
dl,
hr,
table,
blockquote { margin-bottom: 1.3em; }

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child { margin-bottom: 0; }

@media #{$mobile} {
	h2 { font-size: 15px; }
	body { font-size: 12px; }
}