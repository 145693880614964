.universal-parts-items-list{
    position: relative;
}

.universal-parts-title{
    margin-top: 20px;
    margin-bottom: 20px;
}

.universal-parts-title h1{
    font-weight: bold;
    font-size: 18px;
    color: black;
    text-transform: uppercase;
}

@media #{$mobile} {
    .universal-parts-items-list{
        width: auto;
    }
}