/* ------------------------------------------------------------ *\
	Categories
\* ------------------------------------------------------------ */

.categories {
  //   padding: 10px;
  //   background: #f7f7f7;
  //   box-shadow: 0 3px 14px rgba(0, 0, 0, 0.3);
}

.categories .categories__head {
  width: 100%;
  max-width: 230px;
  margin: 0 auto;
}

.categories .categories__list {
  list-style: none outside none;
}
.categories .categories__list li + li {
  margin-top: 8px;
}

.categories .categories__title {
  display: flex;
  margin-bottom: 10px;
  color: #0066ff;
  font-weight: bold;
  cursor: pointer;
}

.categories .categories__show-hide{
  color: #0066ff;
  cursor: pointer;
}

.categories .categories__link {
  position: relative;
  display: block;
  width: 100%;
  padding: 7px 30px 8px 15px;
  background: #757171;
  font-size: 20px;
  color: #fff;
  text-align: left;
}
.categories .categories__link:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  border-width: 5px 0 5px 10px;
  border-style: solid;
  border-top-color: transparent;
  border-bottom-color: transparent;
  margin-top: -5px;
}
.categories .categories__link:hover {
  background: $color-darkgray;
}

.categories .categories__list--many-items li + li {
  margin-top: 3px;
}
.categories .categories__list--many-items .categories__link {
  padding: 3px 30px 3px 12px;
  font-size: 15px;
}
.categories__header {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  margin: 0 -15px 0;
}

@media #{$mobile} {
  .categories {
    padding: 0;
    margin-bottom: 15px;
    background: transparent;
    box-shadow: none;
  }

  .categories .categories__head{
    margin-left: 0;
  }

  .categories .categories__title {
    font-size: 15px;
  }

  .categories .categories__list {
    display: flex;
    flex-flow: wrap;
    margin: 0 -6px;
  }

  .categories .accordion-menu{
    max-width: 100%;
  }

  .categories .dropdownlink, .submenuItems a{
    text-align: center;
  }

  .categories .categories__list li {
    width: 50%;
    padding: 3px 6px;
  }
  .categories .categories__list li + li {
    margin: 0;
  }

  .categories .categories__link {
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 10px 15px;
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
  }
  .categories .categories__link:before {
    display: none;
  }

  .categories .categories__head {
    position: relative;
    margin-bottom: 15px;
  }
}
