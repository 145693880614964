/* ------------------------------------------------------------ *\
	Category Selection
\* ------------------------------------------------------------ */

.category-selection { display: flex; padding: 20px 0; font-size: 15px; font-style: italic;}
.category-selection .category-selection__categories { list-style: none outside none; color: $color-blue; text-transform: uppercase; margin-right: 40px;}
.category-selection strong + strong:before { content: '/'; display: inline-block; vertical-align: middle; margin: 0 5px; }
.category-selection strong { font-weight: normal; color: $color-blue; text-transform: uppercase; }
.category-selection .category-selection__title { margin-right: 10px; width: 125px; }
.category-selection .categories__head-actions { display: none; }
.category-selection__year, .category-selection__make, .category-selection__model { cursor: pointer; }
.category-selection__year, .category-selection__make { text-decoration: underline; }

@media #{$mobile} {
	.category-selection { height: fit-content; padding: 10px 15px; border-bottom: 1px solid #ccc; width: 110%;}
	.category-selection .category-selection__title { display: none; }
	.category-selection .categories__head-actions { display: block; position: absolute; right: 10px; font-size: 20px; color: #000; }
	.category-selection__year, .category-selection__make, .category-selection__model { cursor: initial; }
	.category-selection__year, .category-selection__make { text-decoration: none; }
}