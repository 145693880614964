.universal-dropdown{
    display: flex;
    width: fit-content;
    margin: 20px auto 20px auto;
    max-width: 535px;
}

.batteries-dropdown{
    margin-right: 35px;
    max-height: 30px;
}

.batteries-dropdown,
.chemicals-dropdown{
    text-align: center;
    cursor: pointer;
    width: 250px;
    display: block;
    font-size: 14px;
    color: white;
    position: relative;
    transition: all 0.4s ease-out;
    background: #4360c0;
}

.batteries-dropdown h3,
.chemicals-dropdown h3{
    text-transform: uppercase;
    color: white;
    height: 30px;
    position: relative;
    top: 6px;
}

.universal-item{
    background-color: lightgrey;
    display: block;
    font-size: 12px;
    padding: 5px 12px 5px 12px;
    transition: all 0.4s ease-out;
}

.universal-item a{
    color: black;
    text-decoration: none;
}

.universal-item:hover{
    background-color: gray;
    a{
        color: white;
    }
}

@media #{$mobile} {
    .universal-dropdown{
        display: block;
    }

    .batteries-dropdown{
        margin-bottom: 30px;
        margin-right: 0;
    }
}