/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer { padding: 10px 0; border-top: 1px solid #ccc; }

/*  Footer Nav  */
.footer .footer__nav { margin-bottom: 7px; font-size: 13px; }
.footer .footer__nav ul { display: flex; list-style: none outside none; }
.footer .footer__nav li + li { position: relative; padding-left: 15px; margin-left: 10px; }
.footer .footer__nav li + li:before { content: ''; position: absolute; top: 6px; left: 0; width: 3px; height: 3px; border-radius: 50%; background: currentColor; }
.footer .footer__nav a { text-decoration: none; }
.footer .footer__nav a:hover { color: $color-blue; }

/*  Copyright  */

.copyright { font-size: 10px; color: $color-lightgray; }

@media #{$mobile} {
	.footer { text-align: center; }
	.footer .footer__nav ul { justify-content: center; }
}