/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */

.container {
  display: flex;
  padding-top: 10px;
}

@media #{$mobile} {
  .container {
    display: block;
  }
}
