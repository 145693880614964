/* ------------------------------------------------------------ *\
	Filter Results
\* ------------------------------------------------------------ */


.filter-results__button { 
  right: 15px;
  padding: 20px 0;
  font-size: 15px;
  position: absolute;
  color: red;
  font-weight: bold;
  outline: none;
  border: none;
  background-color: transparent;
}

.filter-results__pop-up {
  font-size: 12px;
  height: fit-content;
  top: 45px;
  color: #646566;
  border: 1px solid #cccccc;
  background: #f8f8f8;
  width: 240px;
  border-radius: 4px;
  outline: none;
  padding: 15px;
  z-index: 100;
}

.filter-results__close-button {
  float:right;
  padding:2px 5px;
  border: none;
  background:#000;
}

.filter-results{
  margin: 0 15px 15px 15px;
}

.filter-results-search,
.filter-results-universal{
  margin: 20px 15px 15px 15px;
}

.filter-results input,
.filter-results-search input,
.filter-results-universal input{
  position: unset;
  opacity: unset;
}

.filter-results__title{
  margin: 0 auto;
  max-width: 230px;
}

.filter-results__title h3{
  width: max-content;
  margin-bottom: 10px;
  color: #0066ff;
  font-weight: bold;
  display: flex;
  cursor: pointer;
}

.filter-results__title h4{
  color: #0066ff;
  cursor: pointer;
}

.filter-results label,
.filter-results-search label,
.filter-results-universal label{
  vertical-align: sub;
  display: inline-block;
  width: 186px;
}

@media #{$mobile} {
  .filter-results{
    margin: 0 0 15px 0;
  }

  .filter-results-search,
  .filter-results-universal{
    margin: 15px 0 15px 0;
  }

  .filter-results__title{
    margin-left: 0;
  }

  .filter-results label,
  .filter-results-search label,
  .filter-results-universal label{
    width: fit-content;
  }

  .filter-results__pop-up,
  .filter-checkbox > div:first-of-type{
    width: 100%!important;
  }
}