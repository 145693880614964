/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */

.shell { position:relative; max-width: $shell-max-width; padding-left: 15px; padding-right: 15px; margin: auto; }

@media (max-width: 1220px) {
	.shell{
		max-width: 1000px;
	}
}