/* ------------------------------------------------------------ *\
	Item
\* ------------------------------------------------------------ */

.item {
  padding: 17px 23px 5px 26px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.item hr {
  height: 1px;
  border: none;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: $color-lightgray;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.3);
}

.item + .item {
  margin-top: 23px;
}

.item .item__main {
  display: block;
}

.item__title__button{
  width: 100%;
  display: flex;
}

.item__title__button .item__title__sub-brand{
  margin-bottom: 10px;
  max-width: 700px;
}

.item__title__button .item__title__sub-brand h2{
  margin: 0;
  font-weight: bold;
  color: black;
}

.item__title__button .item__title__sub-brand span{
  font-size: 13px;
  color: black;
}

.item__title__button .item__part__number{
  display: flex;
}

.item__title__button .item__part__number .item__acdelco__part,
.item__title__button .item__part__number .item__gm__part{
  display: grid;
  height: fit-content;
}

.item__title__button .item__part__number .item__acdelco__part{
  margin-right: 30px;
}

.item__title__button .item__part__number .item__acdelco__part label,
.item__title__button .item__part__number .item__gm__part label{
  font-size: 13px;
  color: #666666;
}

.item__title__button .item__part__number .item__acdelco__part span,
.item__title__button .item__part__number .item__gm__part span{
  font-size: 20px;
  color: #0066ff;
}

.item__image__description{
  display: flex;
}

.item .item__aside {
  width: 200px;
  margin-right: 20px;
}

.item .item__foot {
  width: 170px;
  margin-left: 25px;
  padding-top: 2px;
}

.item__modal__close{
  cursor: pointer;
  font-size: 45px;
  color: black;
  float: right;
  bottom: 10px;
  position: relative;
}

.item .item__image {
  margin-bottom: 15px;
  height: 150px;
}

.item .item__image img {
  width: auto;
  height: auto;
}

.item .item__actions {
  font-size: 12px;
  color: $color-lightgray;
  text-align: center;
  text-transform: uppercase;
  display: none;
}

.item .item__actions-link {
  text-transform: uppercase;
}

.item .item__id {
  position: absolute;
  top: 0;
  right: 0;
}
.item .item__description {
  color: $color-darkgray;
  display: flex;
  font-size: 13px;
}

.item .item__description ul {
  list-style: none outside none;
}

.item .item__description li {
  margin-bottom: 0px;
}

.item .item__description strong {
  font-weight: normal;
  text-transform: uppercase;
  color: $color-gray-item-titles;
}

.item .item__description span {
  color: $color-black;
}

.item .item__description .item__label {
  margin-right: 7px;
}

.item .item__quantity{
  height: 20px;
}

.item .item__quantity .quantity__control{
  width: 40px;
  height: 30px;
  margin-left: 7px;
}

.item .item__foot svg{
  margin-right: 5px;
}

.item .item__add__shoplist{
  display: flex;
}

.item .item__btns__list,
.item .item__add__shoplist{
  margin-left: auto;
}

.item .btn--block{
  display: inline-block; 
  vertical-align: middle; 
  padding: 0 15px; 
  border: 0; 
  border-radius: 0; 
  background: linear-gradient(to bottom, #0f6c9e 0%, #004588 100%); 
  color: #fff; 
  line-height: 23px; 
  text-align: center; 
  appearance: none; 
  text-decoration: none; 
}

.item .item__btns__list .btn--block{
  margin-top: 0;
  width: 100px;
  float: right;
}

.item .item__btns__ok .btn--block{
  margin-top: 0;
  margin-left: 10px;
  width: 100px;
  //float: right;
}

.item .btn--block:hover{
  background: linear-gradient(to bottom, #004588 0%, #0f6c9e 100%);
}

.item .show-on-mobile {
  display: none;
}

.item .item__product-description {
  color: $color-gray-item-titles;
}

.item .item__extras {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #0066ff;
  padding-top: 15px;
  padding-bottom: 15px;
}

.item .item__extras .faq__question {
  margin-bottom: 2px;
  color: #999;
}

.item .item__extras .faq__question strong {
  color: #666;
}

.item .item__extras .faq__answer {
  margin-bottom: 15px;
  color: #999;
}

.item .item__extras .faq__answer strong {
  color: #666;
}

.item .item__extras .extras_list ul li {
  list-style-type: disc;
  list-style-position: inside;
  margin-bottom: 15px;
  color: #666;
}

.item .item__extras .extras_list ul p {
  margin-bottom: 15px;
  color: #666;
}

.item .item__extras .extras_list ul li span {
  color: #999;
}

.item .item__extras__headers {
  width: 100%;
  color: #0066ff;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.item .item__extras .item__extras__headers button {
  outline: none;
  border: none;
  background-color: transparent;
}

.item .item__extras .item__extras__headers .empty {
  outline: none;
  border: none;
  background-color: transparent;
  color: #999;
  pointer-events: none;
}

.item .item__extras .item__extras__headers .item__faq__empty {
  color: #999;
  pointer-events: none;
}

.item .item__extras .item__extras__headers .empty {
  outline: none;
  border: none;
  background-color: transparent;
  color: #999;
  pointer-events: none;
}

.item .item__extras .item__extras__headers .item__faq__empty {
  color: #999;
  pointer-events: none;
}

.item .item__extras__uncollapsed {
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: #0066ff;
  padding-top: 15px;
  height: 600px;
}

.item .item__extras .specifications {
  color: #999;
}

// #menu #list {
//     max-height: 0;
//     transition: max-height 0.15s ease-out;
//     overflow: hidden;
//     background: #d5d5d5;
// }

// #menu:hover #list {
//     max-height: 500px;
//     transition: max-height 0.25s ease-in;
// }

.items-list-search {
  margin-bottom: 10px;
}

.items-list-vehicle {
	color: #000000;
	font-size: 16px;
}

@media #{$mobile} {
  .item {
    position: relative;
    display: block;
    padding: 8px 10px 2px 10px;
    height: auto;
    width: auto;
  }

  .item + .item {
    margin-top: 20px;
  }

  .item .item__aside {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 82px;
    height: 82px;
    padding: 0;
    margin: 0;
  }

  .item .item__foot {
    width: auto;
    height: auto;
    margin: 0;
    float: left;
  }

  .item .item__actions {
    font-size: 9px;
    display: none;
  }

  .item .item__title {
    width: auto;
    font-size: 12px;
    padding-left: 90px;
  }

  .item .item__image {
    margin-bottom: 5px;
    height: 100%;
  }

  .item .item__description {
    font-size: 12px;
  }

  .item .item__description ul {
    display: flex;
    flex-flow: wrap;
    margin: 0 -5px;
  }

  .item .item__description ul li {
    padding: 0 5px;
    margin-bottom: 4px;
    margin-left: 90px;
  }

  .item .item__description div {
    margin: 5px 0 5px 0;
    width: 100px;
  }

  .item .item__description li:nth-child(1),
  .item .item__description li:nth-child(2) {
    width: 100%;
  }

  .item .item__description .item__label,
  .item .item__description .item__info{
    width: fit-content;
  }

  .item .carousel-dots{
    display: none;
  }

  .item .carousel-prevBtn{
    left: 1px!important;
    bottom: -10px!important;
  }

  .item .carousel-nextBtn{
    right: 1px!important;
    bottom: -10px!important;
  }

  .item .show-on-mobile {
    display: block;
  }

  .item .submit__quantity{
    display: flex;
    margin: 15px 0 5px 0;
  }

  .item .submit__quantity ul:nth-child(2){
    margin: 4px 0 0 10px;
  }

  .item .show-on-mobile .item__counter{
    display: flex;
    width: 60px;
  }

  .item .show-on-mobile .item__counter button{
    width: 20px;
    height: 20px;
    background-color: white;
    color: gray;
    border: 0.1px solid gray;
  }

  .item .show-on-mobile .item__counter span{
    text-align: center;
    width: 15px;
    color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item .show-on-mobile .btn--block{
	  width: 75px;
	  height: 28px;
	  margin-top: 0;
	  font-size: 10px;
  }	

  .item .item__btns__list,
  .item .item__btns__ok,
  .item .item__quantity{
    display: none;
  }
  
  .item .item__btns {
    display: none;
    width: 70px;
    font-size: 90px;
  }

  .item .item__extras {
    margin-right: 20px;
  }

  .item .item__extras .item__extras__headers button {
    width: 25%;
  }

  .item .item__extras .item__extras__headers .item__faq {
    width: 12%;
  }

  .item .item__extras .item__extras__headers .item__faq__empty {
    width: 12%;
    color: #999;
    pointer-events: none;
  }
  .item .item__extras__headers{
    display: grid;
    width: fit-content;
  }

  .item .item__extras__headers button{
    width: 100%!important;
    text-align: left;
    line-height: 18px;
  }

}

@media (max-width: 370px) {
  .item .item__part__number{
    display: block;
  }
}
