/* ------------------------------------------------------------ *\
	Button Blank
\* ------------------------------------------------------------ */

.btn-blank { border: 0; background: transparent; appearance: none; }

/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn { display: inline-block; vertical-align: middle; height: 23px; padding: 0 15px; border: 0; border-radius: 0; background: linear-gradient(to bottom, #0f6c9e 0%, #004588 100%); font-size: 12px; color: #fff; line-height: 23px; text-align: center; appearance: none; text-decoration: none; }

.btn img,
.btn svg,
.btn i { margin-right: 5px; }

.btn:hover { background: linear-gradient(to bottom, #004588 0%, #0f6c9e 100%) }

.btn--block { display: block; height: 30px ;width: 100%; margin-top: 60px; padding-left: 0; padding-right: 0 ; font-size: 15px ; font-weight: bold; }