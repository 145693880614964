
/* Declare some variables */
$acdelco-blue: #005DAB;
$base-color: #cbcbcb;
$light-background: lighten(desaturate($base-color, 50%), 12.5%);

.pagination,
.pagination-bottom{
  padding-right: 1.5rem;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
}

.pagination{
  padding-bottom: 0.4rem;
}

.pagination-bottom{
  padding-top: 0.8rem;
}


.pagination-bottom .inner{
  flex-direction: row;
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  justify-content: flex-end;
}
.pagination .left,
.pagination-bottom .left{
  align-items: center;
  flex-direction: row;
  display: flex;
}

.pagination .left .page__indicator,
.pagination-bottom .left .page__indicator{
  color: #6c757d;
  padding-left: 1.5rem;
  height: 100%;
  display: inline-block;
}

.pagination .right,
.pagination-bottom .right{
  // padding-bottom: 1.5rem;
  // padding-top: 1.5rem;
  align-items: center;
  flex-direction: row;
  display: flex;
}

.total__parts {
  color: #343a40;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
  // padding-top: 0.5rem;
  margin: 0;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.2;
}

.total__parts__border {
  border-right: 1px solid #dee2e6;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: $acdelco-blue;
  border-color: $acdelco-blue;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.3rem 0.45rem;
  margin-left: -1px;
  line-height: 1.25;
  color: $acdelco-blue;
  background-color: #fff;
  border: 1px solid #dee2e6;
  text-decoration: underline;
  transition: 0.15s;
  font-size: 0.7rem;
  cursor: pointer;
}

a.page-link {
  border-color: $base-color;
  text-decoration: underline;
  transition: 0.15s;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
}
