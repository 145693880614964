/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro { height: 400px; background-size: cover; background-position: 50% 50%; }

@media #{$mobile} {
	.intro { height: 0; padding-top: 37%; }
}

