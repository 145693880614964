/* ------------------------------------------------------------ *\
	Title
\* ------------------------------------------------------------ */

.title {
  padding: 20px 0;
  font-size: 15px;
  font-style: italic;
  color: $color-gray;
}

.title-filter-big {
	font-weight: bold;
	color: #000000;
	font-size: 22px;
}

.title-filter-small {
	font-weight: bold;
  color: #000000;
  margin-bottom: 0.7em !important; 
}

@media #{$mobile} {
  .title {
    padding-top: 0;
  }
}